import request from '@/utils/request'

/*
@keyword : manager-api-hosts
*/
export function fetchList(query) {
  return request({
    url: 'dashboard',
    method: 'get',
    params: query
  })
}

export function store(data) {
  return request({
    url: '/dashboard',
    method: 'post',
    data
  })
}

export function show(id) {
  return request({
    url: `dashboard/`,
    method: 'get',
    params: { id }
  })
}

export function clientPortfolio(id) {
  return request({
    url: `client-portfolio/`,
    method: 'get',
    params: { id }
  })
}

export function edit(id) {
  return request({
    url: `dashboard/${id}`,
    method: 'get'
  })
}

export function update(id, data) {
  return request({
    url: `dashboard/${id}`,
    method: 'put',
    data
  })
}

export function destroy(id) {
  return request({
    url: `dashboard/${id}`,
    method: 'delete'
  })
}


export function fetchBrokerDashboard(id) {
  return request({
    url: `dashboard/broker/`,
    method: 'get',
    params: { id }
  })
}

export function superadminBrokerClient(data) {
  return request({
    url: '/dashboard/broker/client',
    method: 'post',
    data
  })
}

export function downloadBrokerPLList(id) {
  return request({
    url: `download/broker/active`,
    method: 'get',
    params: { id }
  })
}
