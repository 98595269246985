<template>
  <div>
    <el-skeleton :throttle="100" :loading="tableLoadingStatus" animated>
      <template slot="template">
        <div v-for="r in pageSize / 2" :key="r" style="padding: 5px">
          <el-skeleton-item variant="text" style="width: 29%" />&nbsp;&nbsp;
          <el-skeleton-item variant="text" style="width: 29%" />&nbsp;&nbsp;
          <el-skeleton-item variant="text" style="width: 29%" />&nbsp;&nbsp;
          <el-skeleton-item variant="text" style="width: 10%" />
        </div>
      </template>
    </el-skeleton>

    <div v-if="!tableLoadingStatus" class="table-wrapper">
      <el-table
        :data="tableDataItems"
        stripe
        :show-summary="showSummary"
        sum-text="Total"
        :row-class-name="tableRowClassName"
        @selection-change="handleMultipleSelectionChange"
        max-height="600px"
      >

        <!-- TABLE VIEW ACTION  -->
        <el-table-column
          fixed
          label="Actions"
          v-if="tableActionShowVisibility"
          width="142"
        >
          <template slot-scope="scope">
            <el-button
              v-if="tableActionViewVisibility"
              @click="handleViewDetails(scope.row)"
              type="primary"
              icon="el-icon-view"
              circle
            ></el-button>

            <el-button
              v-if="tableActionDollarVisibility"
              @click="handleDollarBtnDetails(scope.row)"
              type="success"
              circle
            >$</el-button>

             <!-- TABLE EDIT ACTION  -->
            <el-button
              v-if="tableActionEditVisibility"
              @click="handleEdit(scope.row)"
              type="info"
              icon="el-icon-edit-outline"
              circle
              ></el-button
            >
             <!-- TABLE logout ACTION  -->
            <el-button
              v-if="tableActionLogoutVisibility && scope.row.is_login"
              @click="handleLogout(scope.row)"
              type="danger"
              icon="el-icon-remove"
              circle
              class="back-ground-red"
              ></el-button
            >

            <!-- TABLE DELETE ACTION  -->
            <el-button
              v-if="tableActionDeleteVisibility"
              @click="handleDelete(scope.row)"
              type="danger"
              icon="el-icon-delete"
              circle
            ></el-button>
          </template>
        </el-table-column>

        <!-- TABLE ALL ACTIONS -->
        <el-table-column
          v-if="tableActionVisibility"
          label="Actions"
          width="110"
        >
          <template slot-scope="scope">
            <el-dropdown
              v-if="
                (scope.row.allow_edit_delete &&
                  scope.row.allow_edit_delete === true) ||
                scope.row.allow_edit_delete === undefined
              "
            >
              <span class="el-dropdown-link">
                <svg-icon
                  icon-name="more-options2"
                  icon-class="svg-icon-medium"
                  icon-style="color: green"
                />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="tableActionViewDetailsVisibility">
                  <el-button
                    @click="handleViewDetails(scope.row)"
                    type="text"
                    icon="el-icon-view"
                    >View</el-button
                  >
                </el-dropdown-item>

                <el-dropdown-item v-if="tableActionEditVisibility">
                  <el-button
                    @click="handleEdit(scope.row)"
                    type="text"
                    icon="el-icon-edit-outline"
                    >Edit</el-button
                  >
                </el-dropdown-item>
                <el-dropdown-item v-if="tableActionDeleteVisibility">
                  <el-button
                    @click="handleDelete(scope.row)"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    >Delete</el-button
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>


        <!-- IMAGE VIEW  -->
         <el-table-column
          v-if="tableCheckBoxVisibility"
          type="selection"
          width="55"
          :selectable="isTableCheckBoxVisibilityRowWise"
        >
        </el-table-column>
        <el-table-column
          class="xyz"
          v-for="(config, key) in tableConfig"
          :label="config.label ? config.label : tableLabel(config.prop)"
          :prop="config.prop"
          :sortable="config.sortable ? 'custom' : ''"
          :width="config.width"
          :class-name="config.className"
          :key="key"
        >
          <template slot-scope="scope">
            <span v-if="typeof scope.row[config.prop] === 'object'">
              <span
                v-for="(item, index) in scope.row[config.prop]"
                :key="index"
              >
                <span v-if="isImgUrl(item.url)">
                  <el-image
                    class="medium"
                    :src="item.url"
                    :preview-src-list="[item.url]"
                  >
                  </el-image>
                </span>

                <span v-else-if="getExt(item.url)">
                  <iframe :src="item.url"></iframe>
                </span>

                <span v-else>
                  <el-tag>{{ item }}</el-tag
                  >&nbsp;
                </span>
              </span>
            </span>
            <!-- <span v-else>
              {{ scope.row[config.prop] }}
            </span> -->
            <span v-else>
              <!-- if popover is on for the column -->
              <span v-if="config.popover">
                <span>
                  <el-link type="primary" @click="handleViewDetails(scope.row)" :underline="false">
                    <el-button size="mini" type="primary" round>{{ scope.row[config.prop] }}</el-button>
                  </el-link>
                </span>
              </span>
              <span v-else>
                
                  <template v-if="tableActionLogoutVisibility && scope.row.is_login && config.prop == 'name'"> 
                  <span class="logged-in">●</span>
                    {{ scope.row[config.prop] }}</template>
                    
                  <template v-else-if="scope.row.is_holding_fund_available == 'No' && config.prop == 'is_holding_fund_available'" class="back-ground-red" > <span class="back-ground-red">{{ scope.row[config.prop] }}</span> 
                  </template>

                  <template v-else-if="scope.row.is_holding_fund_available == 'Yes' && config.prop == 'is_holding_fund_available'" > <span class="back-ground-green">{{ scope.row[config.prop] }}</span></template>

                  <template v-else> {{ scope.row[config.prop] }}</template>
              </span>
            </span>
          </template>
        </el-table-column>



        <!-- // WHEN NO DATA FOUND -->
        <div slot="empty" class="no-data">
          <svg-icon icon-name="no-data" icon-class="svg-icon-xlarge" />
          <div>Sorry! No data found.</div>
        </div>
      </el-table>

      <!-- // PAGINATION -->
      <el-pagination
        v-if="tableDataTotal"
        :total="tableDataTotal"
        :current-page.sync="tableQuery.page"
        :page-size.sync="tableQuery.limit"
        layout="prev, pager, next"
        @size-change="handlePagination()"
        @current-change="handlePagination()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Table",
  props: {
    tableKey: {
      type: String,
      default: "",
    },
    tableConfig: {
      type: Array,
      default: function () {
        return [];
      },
    },
    tableData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    tablePagination: {
      type: Object,
      default: function () {
        return {};
      },
    },
    tableQuery: {
      type: Object,
      default: function () {
        return {};
      },
    },
    showSummary: {
      type: Boolean,
      default: true,
    },
    tableActionVisibility: {
      type: Boolean,
      default: true,
    },
    tableActionShowVisibility: {
      type: Boolean,
      default: true,
    },
    tableActionViewDetailsVisibility: {
      type: Boolean,
      default: false,
    },

    tableActionEditVisibility: {
      type: Boolean,
      default: true,
    },
    tableActionViewVisibility: {
      type: Boolean,
      default: true,
    },
    tableActionDollarVisibility: {
      type: Boolean,
      default: false,
    },
    tableHandleEditProperty: {
      // to decide which property value to emit
      type: String,
      default: null,
    },
    tableActionDeleteVisibility: {
      type: Boolean,
      default: false,
    },
    tableActionLogoutVisibility: {
      type: Boolean,
      default: false,
    },
    

    tableLoadingStatus: {
      type: Boolean,
      default: false,
    },

    tableCheckBoxVisibility: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      multipleSelection: [],
      tableCheckBoxVisibilityDisableKeyValueKeys: [],

    };
  },
  created() {
    if (this.tableCheckBoxVisibilityDisableKeyValue) {
      this.tableCheckBoxVisibilityDisableKeyValueKeys = Object.keys(
        this.tableCheckBoxVisibilityDisableKeyValue
      );
    }
  },
  computed: {
    tableDataItems() {
      return this.tableData.data ? this.tableData.data.item : [];
    },
    tableDataTotal() {
      return this.tablePagination ? this.tablePagination.total : 0;
    },
    tableLabel() {
      return (prop) => `${this.tableKey.label}.list.${prop}`;
      // this.trans(`${this.$route.meta.module}.${this.tableKey}.list.${prop}`);
    },
  },
  methods: {
    // check multiple selection on or off
    tableRowClassName({row}) {
        if (row.trade_status == "active") {
          return 'success-row';
        } else if (row.trade_status == "closed") {
          return 'warning-row';
        }
        return '';
      },
    isTableCheckBoxVisibilityRowWise(row) {
      let toReturn = true;

      this.tableCheckBoxVisibilityDisableKeyValueKeys.forEach((key) => {
        if (key in row) {
          if (row[key] == this.tableCheckBoxVisibilityDisableKeyValue[key]) {
            toReturn = false;
          }
        }
      });

      return toReturn;
    },
    handleMultipleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit("multipleSelection", this.multipleSelection);
    },
    handlePagination() {
      this.$emit("pagination");
    },
    handleViewDetails(row) {
      this.$emit("view", row);
    },
    handleDollarBtnDetails(row) {
      this.$emit("dollar", row);
    },
    handleLogout(row) {
      this.$emit("logout", row);
    },
    handleEdit(row) {
      if (this.tableHandleEditProperty) {
        this.$emit("edit", row[this.tableHandleEditProperty]);
      } else {
        this.$emit("edit", row.id);
      }
    },
    handleDelete(row) {
      // this.$emit("delete", row.id);
      this.$emit("delete", row);
    },
  },
};
</script>


